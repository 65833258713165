%banner-style{
  background-attachment: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -80px;
  padding-top: 80px;
  position: relative;

  &:before{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#111420+0,111420+100&0.52+0,0+100 */
    background: -moz-linear-gradient(-45deg, rgba(17,20,32,0.52) 0%, rgba(17,20,32,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(17,20,32,0.52) 0%,rgba(17,20,32,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(17,20,32,0.52) 0%,rgba(17,20,32,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#85111420', endColorstr='#00111420',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .container{
    position: relative;
    z-index: 5;
  }
}

#hero-banner, 
.hero-banner{
  @extend %banner-style;
  margin-bottom: 55px;
  text-align: center;

  &.is-about{
    background-image: url(../img/banner-about-xs.jpg);

    @include tablet-screen{
      background-image: url(../img/banner-about.jpg);
    }
  }

  &.is-coverages{
    background-image: url(../img/banner-coverages-xs.jpg);

    @include tablet-screen{
      background-image: url(../img/banner-coverages.jpg);
    }
  }

  &.is-faq{
    background-image: url(../img/banner-faq-xs.jpg);
    background-position: bottom center;

    @include tablet-screen{
      background-image: url(../img/banner-faq.jpg);
    }
  }

  .container{
    padding-bottom: 60px;
    padding-top: 60px;

    @include tablet-screen{
      padding-bottom: 140px;
      padding-top: 140px;
    }
  }

  h1, .title{
    color: $white;
    @include rem(font-size, 45px);
    font-weight: 300;
    text-shadow: 1px 1px 4px rgba(0,0,0,0.6);
    text-transform: none;

    @include tablet-screen{
      @include rem(font-size, 62px);
    }
  }
}