%title-style{
  color: $blue;
  font-family: $title-font;
  font-weight: 600;
  margin-bottom: 15px;
  text-transform: uppercase;

  a{
    color: $blue;
    text-decoration: none;

    &:hover, &:focus, &:active{
      color: $light-blue;
    }
  }
}

h1{
  @extend %title-style;
  @include rem(font-size, 18px);

  @include tablet-screen{
    @include rem(font-size, 23px);
  }
}

h2{
  @extend %title-style;
}

h3{
  @extend %title-style;
}

h4, h5{
  @extend %title-style;
}

.thin-title,
h1.thin-title,
h2.thin-title,
h3.thin-title,
h4.thin-title{
  color: $c-text;
  @include rem(font-size, 20px);
  font-weight: 300;
  text-transform: none;
}

.primary-title,
h1.primary-title,
h2.primary-title,
h3.primary-title,
h4.primary-title{
  color: $c-primary;
  @include rem(font-size, 20px);
  font-weight: 300;
  text-transform: uppercase;
}


.smallest-title,
h1.smallest-title,
h2.smallest-title,
h3.smallest-title,
h4.smallest-title{
  color: $c-text;
  @include rem(font-size, 16px);
  font-weight: 300;
  margin: 0 0 5px;
  text-transform: none;
}


.subtitle,
h1.subtitle,
h2.subtitle,
h3.subtitle,
h4.subtitle{
  @include rem(font-size, 26px);
  text-transform: none;
}


.small-title,
h1.small-title,
h2.small-title,
h3.small-title,
h4.small-title{
  @include rem(font-size, 1.75rem);
  text-transform: none;
}


.primary-subtitle,
h1.primary-subtitle,
h2.primary-subtitle,
h3.primary-subtitle,
h4.primary-subtitle{
  color: $c-primary;
  @include rem(font-size, 26px);
  font-weight: 400;
  margin: 60px 0 25px;
  text-transform: uppercase;

  &:first-child{
    margin-top: 0;
  }
}