.contact-list{
  @extend %unstyledList;

  span{
    color: $c-secondary;
    display: inline-block;
    margin-right: 15px;
    min-width: 45px;
  }

  a{
    color: $c-text;
    text-decoration: none;

    @include onHover{
      text-decoration: underline;
    }
  }
}