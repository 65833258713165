#faq-accordion{
  .js-badger-accordion-header{
    border: 2px solid $c-primary;
    color: $c-primary;
    font-weight: 400;
    padding: 10px 45px 10px 15px;

    &:before{
      background: $c-primary;
      height: 5px;
      right: 17px;
      top: 19px;
      width: 21px;
    }

    &:after{
      background: $c-primary;
      height: 21px;
      right: 25px;
      top: 11px;
      width: 5px;
    }
  }

  .badger-accordion__panel {
    border: 1px solid #eae9e9;
    border-width: 0 1px 1px;
  }
  .js-badger-accordion-panel-inner{
    background: #f5f5f5;
    padding: 10px 30px;
  }
}