a{
  color: $c-secondary;
  text-decoration: underline;

  @include onHover{
    color: #cd6825;
    text-decoration: underline;
  }
}

%actionLink{
  color: $c-text;
  display: inline-block;
  @include rem(font-size, 15px);
  padding: 5px 30px 5px 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;

  &:hover, &:focus, &:active{
    color: $c-primary;
    text-decoration: none;
  }
}
%actionLink-icon{
  font-weight: bold;
  position: absolute;
  right: 0;
}

.toggle-link{
  @extend %actionLink;

  .material-icons{
    @extend %actionLink-icon;
    color: #29c321;
    top: 2px;
  }
}

.remove-link{
  @extend %actionLink;
  
  .material-icons{
    @extend %actionLink-icon;
    color: #a71a1a;
    top: 3px;
  }
}

.primary-link,
a.primary-link{
  color: $c-primary;
  text-decoration: underline;

  @include onHover{
    color: #f86d11;
    text-decoration: underline;
  }
}