%sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}

.sr-only {
  @extend %sr-only;
}

.small-text{
  @include rem(font-size, 14px);
}

.m-bottom-0{
  margin-bottom: 0 !important;
}

.m-bottom-20{
  margin-bottom: 20px !important;
}

.m-bottom-40{
  margin-bottom: 40px !important;
}

.m-bottom-1rem{
  margin-bottom: 1em !important;
  margin-bottom: 1rem !important;
}

.m-top-0{
  margin-top: 0 !important;
}

.m-top-20{
  margin-top: 20px !important;
}

.m-top-40{
  margin-top: 40px !important;
}

.m-top-1rem{
  margin-top: 1em !important;
  margin-top: 1rem !important;
}