#quote-menu{
  @extend %unstyledList;
  counter-reset: step; 
  font-size: 0;
  padding: 40px 0 35px;

  li{
    display: inline-block;
    @include rem(font-size, 12px);
    line-height: 1em;
    vertical-align: top;
    width: 50%;

    @include mobile-screen{
      margin: 0 0 10px;
    }

    @include tablet-screen{
      @include rem(font-size, 14px);
      padding: 0 7px;
      width: 16.66%;
    }

    &.is-active{
      a{
        background: $darker-blue;

        @include onHover{
          background: $darker-blue;
          cursor: not-allowed;
        }

        &:after{
          @include tablet-screen{
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid $darker-blue;
            bottom: -20px;
            content: "";
            display: block;
            height: 0; 
            left: 50%;
            margin-left: -20px;
            position: absolute;
            width: 0; 
          }
        }
      }
    }

    &:first-child{
      padding-left: 0;
    }

    &:last-child{
      padding-right: 0;
    }

    &:nth-child(odd){
      @include mobile-screen{
        padding: 0 5px 0 0;
      }
    }    

    &:nth-child(even){
      @include mobile-screen{
        padding: 0 0 0 5px;
      }
    }

    a{
      background: $gray;
      color: $white;
      display: block;
      letter-spacing: 0;
      padding: 10px 0 10px 5px;
      position: relative; 
      text-align: left;
      text-decoration: none;

      @include tablet-screen{
        letter-spacing: 0.03em;
        min-height: 50px;
        padding: 0 15px 0 25px;
      }

      @include desktop-screen{
        padding-left: 65px;
      }

      @include onHover{
        background: #403f3e;
        text-decoration: none;
      }

      &:before{
        counter-increment: step; 
        color: $white;
        content: counter(step);
        display: inline-block;
        font-family: $title-font;
        @include rem(font-size, 19px);
        font-weight: 300;
        left: 0;
        line-height: 0.59em;
        margin-right: 3px;
        min-height: 100%;
        text-align: center;
        top: 0;
        vertical-align: middle;

        @include tablet-screen{      
          display: block;
          @include rem(font-size, 35px);
          margin-right: 0;
          min-height: 30px;
          position: absolute;
          top: 12px;
          width: 25px;
        }

        @include desktop-screen{      
          @include rem(font-size, 75px);
          min-height: 100%;
          top: 0;
          width: 65px;
        }
      }

      & > span{
        display: inline-block;
        max-width: 115px;
        vertical-align: middle;
        
        @include tablet-screen{
          height: 50px;
          @include flexbox();
          @include align-items(center);
          max-width: none;
        }

        span{
          display: inline-block;
          margin: auto;
        }
      }
    }
  }
}