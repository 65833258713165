.footnotes-list{
  @extend %unstyledList;
  counter-reset: step; 
  font-weight: 300;

  em{
    font-style: normal;
    font-weight: 400;
  }

  li{
    &:before{
      counter-increment: step;
      content: counter(step);
      display: inline-block;
      font-weight: 400;
      margin-right: 7px; 
    }
  }
}