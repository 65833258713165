/* form elements */ 

.form-item{
  margin-bottom: 27px;

  @include tablet-screen{
    margin-bottom: 20px;
  }
}

.form-description{
  display: block;
  @include rem(font-size, 14px);
  font-weight: 300;
  line-height: 1.35em;
}

label + .form-description{
  margin-top: 0;
}

/* labels */ 


label{
  color: $black;
  display: block;
  @include rem(font-size, 16px);
  //font-weight: 600;

  @include tablet-screen{
    @include rem(font-size, 17px);
  }

  sup{
    a{
      color: $c-text;
      text-decoration: none;
    }
  }
}

label.is-required,
.is-required label{
  &:after{
    content: "*";
    color: $red;
    display: inline-block;
    font-size: 80%;
    margin-left: 3px;
    position: relative;
    top: -3px;
  }
}

.form-item{
  label{
    line-height: 1em;
    margin-bottom: 0;
  }
}

/* inputs */

label{
  & + input[type=email],
  & + input[type=password],
  & +  input[type=search],
  & + input[type=tel],
  & + input[type=text],
  & + input[type=url],
  & + input[type=number],
  & + .dropdown-wrapper,
  & + .date-wrapper{
    margin-top: 1em;
    margin-top: 1rem;
  }
}

.is-wrapper .is-wrapper{
  .form-item{
    input[type=email],
    input[type=password],
    input[type=search],
    input[type=tel],
    input[type=text],
    input[type=url],
    input[type=number],
    .dropdown-wrapper{
      background: $lightest-gray;
    }
  }
}

.form-item{
  input[type=email],
  input[type=password],
  input[type=search],
  input[type=tel],
  input[type=text],
  input[type=url],
  input[type=number]{
    background: $white;
    border: 1px solid $c-border;
    display: block;
    margin: 0 0 5px;
    padding: 7px 10px;
    width: 100%;

    &.is-invalid,
    &.has-error{
      @include b-shadow(0 0 2px rgba(244, 0, 0, 0.9));
    }
  }

  &.is-invalid,
  &.has-error{
    input[type=email],
    input[type=password],
    input[type=search],
    input[type=tel],
    input[type=text],
    input[type=url],
    input[type=number],
    .dropdown-wrapper,
    .date-wrapper{
      @include b-shadow(0 0 2px rgba(244, 0, 0, 0.9));
    }
  }
}

/* dropdown */
.dropdown-wrapper{
  background: $white;
  border: 1px solid $c-border;
  margin: 0 0 5px;
  overflow: hidden;
  position: relative;
  width: 100%;

  &.is-invalid,
  &.has-error{
    @include b-shadow(0 0 2px rgba(244, 0, 0, 0.9));
  }

  select{
    background: transparent;
    background-image: none;
    border: none;
    @include border-radius(0);
    @include box-shadow(none);
    padding: 7px 10px;
    position: relative;
    -webkit-appearance: none;
    width: 130%;
    z-index: 5;

    @include screen-bp(768px, 991px){    
      padding-left: 3px;
      padding-right: 3px;
    }

    &:hover,
    &:focus{
      background: rgba(0,0,0,0.1);
      cursor: pointer;
      outline: none;
    }
  }

  .material-icons{
    color: $c-text ;
    pointer-events: none;
    position: absolute;
    right: 3px;
    top: 7px;
    z-index: 1;

    @include screen-bp(768px, 991px){
      right: -2px;
      top: 7px;
    }
  }
}

/* date */
.date-wrapper{
  position: relative;
  width: 100%;

  &.is-invalid,
  &.has-error{
    @include b-shadow(0 0 2px rgba(244, 0, 0, 0.9));
  }

  input{
    position: relative;
    z-index: 1;
  }
  .material-icons{
    color: $c-text ;
    pointer-events: none;
    position: absolute;
    right: 6px;
    top: 7px;
    z-index: 5;
  }
}

.to{
  font-weight: bold;
  line-height: 1em;
  margin: 10px 0 13px;
  text-align: center;

  @include desktop-screen{
    margin: 10px 0 13px;
  }
}

.checkbox-wrapper{
  padding: 2px 0 0 20px;
  position: relative;

  input{
    left: 0;
    position: absolute;
    top: 3px;
  }
}

