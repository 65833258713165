header{
  background: rgba(255, 255, 255, 0.9);
  overflow: hidden;
  position: fixed;
  text-align: left;
  width: 100%;
  z-index: 999;

  @include tablet-screen{
    text-align: right;
  }

  &:before{
    background: $c-primary;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    margin-left: -150px;
    @include transition(0.4s margin-left ease-in);
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    @include max-screen(399px){
      margin-left: -100px;
    }

    @include min-screen(500px){
      margin-left: -260px;
    }

    @include min-screen(600px){
      margin-left: -320px;
    }

    @include tablet-screen{
      margin-left: -470px;
    }

    @include desktop-screen{
      margin-left: -710px;
    }

    @include desktopxl-screen{
      margin-left: -900px;
    }

    @include min-screen(1400px){
      margin-left: -980px;
    }
  }

  .container{
    min-height: 80px;
    padding-bottom: 30px;
    padding-top: 25px;
    position: relative;
    @include transition(0.4s max-width ease-in);
    z-index: 5;
  }

  .brand{
    display: inline-block;
    left: 15px;
    opacity: 1;
    position: absolute;
    top: 25px;
    z-index: 1;

    @include onHover{
      opacity: 0.8;
    }

    img{
      color: white;
      @include transition(0.3s width ease-in);
      width: 220px;
      
      @include screen-bp(1px, 349px){
        width: 170px;
      }

      @include tablet-screen{
        width: 240px;
      }
    }
  }
}