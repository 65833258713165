// ADD AN EASIER WAY TO INCLUDE MEDIA QUERYS (breakpoints)
//  How to use:
//  _______________

//  .sidebar {
//    a-cool: style;

//    @include min-screen(100em) {
//      my-breakpoint: style;
//    }

//    @include screen-bp(100em 200em) {
//      my-breakpoint: style;
//    }

//    @include mobile-screen {
//      my-breakpoint: style;
//    }
//  }
//  _______________
//

// Add a query with min-width and max-width

@mixin screen-bp($minwidth, $maxwidth){
  @media (min-width: $minwidth) and (max-width:$maxwidth){
    @content;
  }
}

// Add a query with min-width

@mixin min-screen($minwidth){
  @media (min-width: $minwidth){
    @content;
  }
}

// Add a query with max-width

@mixin max-screen($maxwidth){
  @media (max-width: $maxwidth){
    @content;
  }
}


// Add query for standard sizes

@mixin mobile-screen{
  @media (max-width: ($screen-tablet - 1px)){
    @content;
  }
}

@mixin tablet-screen{
  @media (min-width: $screen-tablet){
    @content;
  }
}

@mixin desktop-screen{
  @media (min-width: $screen-desktop){
    @content;
  }
}

@mixin desktopxl-screen{
  @media (min-width: $screen-large){
    @content;
  }
}