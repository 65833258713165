// ==========================================================================
//
// ACCORDION
//
// Description: Base accordion styles that are ESSENTIAL for the accordion
//
// ==========================================================================

// ==========================================================================
// # BASE
// ==========================================================================

// .badger-accordion {}

ul.js-badger-accordion{
  list-style-type: none;
  margin: 0 0 40px;
  padding: 0;

  li{
    margin: 0 0 20px;
  }
}
.badger-accordion__panel {
  max-height: 75vh;
  overflow: hidden; 
  @include transition(0.4s max-height ease-in-out);
}
  .badger-accordion__panel.-ba-is-hidden {
    max-height: 0 !important;
  }
  .badger-accordion--initalised .badger-accordion__panel {
    @include transition(0.2s max-height ease-in-out);
  }

/*# sourceMappingURL=badger-accordion.css.map */

/* Custom */
/* 
  Created default style, for .outline-accordion refer to scss/modules/_outline-accordion.scss
*/

.badger-accordion__panel {
  max-height: 75vh;
  overflow: hidden; }
  .badger-accordion__panel.-ba-is-hidden {
    max-height: 0 !important; }
  .badger-accordion--initalised .badger-accordion__panel {
    transition: max-height ease-in-out 0.2s; }

.js-badger-accordion-header,
h3.js-badger-accordion-header{
  background: $white;
  color: $dark-gray;
  @include rem(font-size, 20px);
  margin: 0;
  padding: 25px 60px 25px 30px;
  position: relative;
  text-transform: none;

  @include onHover{
    cursor: pointer;
  }

  &.-ba-is-active{
    &:after{
      @include transform(rotate(90deg));
    }
  }

  .material-icons{
    position: absolute;
    right: 10px;
    top: 5px;
  }

  &:before, 
  &:after{
    background: $c-secondary;
    @include border-radius(3px);
    content: "";
    display: block;
    position: absolute;
    @include transform(rotate(0deg));
    @include transition(0.4s);
  }

  &:before{
    height: 6px;
    top: 34px;
    right: 17px;
    width: 22px;
  }

  &:after{
    height: 22px;
    top: 26px;
    right: 25px;
    width: 6px;
  }
}

.js-badger-accordion-panel-inner{
  background: $white;
  @include rem(font-size, 18px);
  padding: 10px 30px 30px;
}


/*# sourceMappingURL=badger-accordion.css.map */