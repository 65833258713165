#main-menu{
  background: $ivory;
  display: inline-block;
  height: 100%;
  font-size: 0;
  left: -100%;
  list-style-type: none;
  margin: 0;
  opacity: 0;
  padding: 55px 0 0;
  position: fixed;
  top: 0;
  @include transition(0.4s all ease-in);
  width: 90%;
  z-index: 999;

  & + .backdrop{
    background: rgba(0,0,0,0.8);
    display: block;
    height: 100%;
    left: -100%;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    @include transition(0.4s all ease-in);
    top: 0;
    width: 100%;
    z-index: 1;

    @include tablet-screen{
      display: none;
    }
  }

  &:after{
    background: $ivory;
    content: "";
    height: 100%;
    left: -100%;
    position: fixed;
    @include transition(0.4s all ease-in);
    top: 0;
    width: 90%;
    z-index: 2;

    @include tablet-screen{
      display: none;
    }
  }

  @include tablet-screen{
    background: transparent;
    display: inline-block;
    height: auto;
    left: auto;
    opacity: 1;
    padding: 0;
    position: relative;
    text-align: right;
    @include transition(none);
    width: auto;
  }

  li{
    border: 1px solid $lighter-gray;
    border-width: 1px 0;
    color: $c-secondary;
    @include rem(font-size, $font-size);
    margin-bottom: 0;
    position: relative;
    vertical-align: top;
    z-index: 5;

    @include tablet-screen{
      color: $c-light-text;
      display: inline-block;
      border: none;
    }

    &:after{
      @include tablet-screen{
        bottom: -1px;
        color: $c-secondary;
        content: " | ";
        display: inline-block;
        font-weight: bold;
        margin: 0 10px;
        position: relative;
      }
    }

    &:first-child{
      border-top: none;
    }

    &:last-child{
      border-bottom: none;

      &:after{
        @include tablet-screen{
          display: none;
        }
      }
    }

    &.is-active{
      a{
        background: $darker-blue;
        border-right: 3px solid $c-primary;
        color: $white;

        @include tablet-screen{
          background: transparent;
          border: none;
          color: $c-primary;
        }
      }
    }
  }

  a{
    color: $c-light-text;
    display: block;
    padding: 22px 20px;
    text-decoration: none;

    @include onHover{
      color: $c-primary;
      text-decoration: none;
    }

    @include tablet-screen{
      display: inline-block;
      padding: 0;
    }
  }
}

.menu-toggle{
  background: transparent;
  color: $c-primary;
  display: inline-block;
  height: 40px;
  position: fixed;
  right: 15px;
  top: 20px;
  width: 40px;

  @include onHover{
    background: $c-primary;
    color: $white;
    cursor: pointer;
  }

  @include tablet-screen{
    display: none;
  }

  .material-icons{
    font-size: 35px;
    position: absolute;
    right: 0;
    top: 0;
    @include transition(0.4s opacity ease-in);
  }

  &.open{
    position: absolute;

    .material-icons{
      border: 3px solid $c-primary;
    }
  }

  &.close{
    opacity: 0;
    pointer-events: none;
    right: 80%;
    @include transition(0.4s right ease-in);

    .material-icons{
      color: $dark-gray;
      opacity: 0;
      pointer-events: none;
    }
  }
}

.is-open{
  #main-menu{
    left: 0;
    opacity: 1;
    text-align: left;
    top: 0;

    @include onHover{
      color: $dark-gray;
    }

    @include tablet-screen{
      height: auto;
      left: auto;
      position: static;
      text-align: right;
      top: auto;
      width: auto;
    }

    & + .backdrop{
      left: 0;
      opacity: 1;
      pointer-events: all;
      width: 100%;

      @include tablet-screen{
        display: none;
      }
    }

    &:after{
      left: 0;

      @include tablet-screen{
        display: none;
      }
    }
  }
  .menu-toggle{
    &.open{
      .material-icons{
        pointer-events: none;
      }
    }

    &.close{
      background: transparent;
      color: $dark-gray;
      display: inline-block;
      opacity: 1;
      pointer-events: all;
      right: 13%;
      top: 8px;
      z-index: 1000;
  
      @include onHover{
        background: transparent;
        color: $c-primary; 
  
        .material-icons{
          color: $c-primary;
        }
      }

      .material-icons{
        opacity: 1;
      }
    }
  }
}