@import "header.scss";
@import "main-menu.scss";
@import "footer.scss";

html, body {
  height: 100%;
  margin: 0;
}
#wrapper {
  min-height: 100%;
}

#main-container {
  padding: 0;
  padding-bottom: 170px;

  @include tablet-screen{
    padding: 0;
    padding-bottom: 50px;
  }
}

footer {
  height: 170px;
  margin-top: -170px;

  @include tablet-screen{
    height: 50px;
    margin-top: -50px;
  }

  p{
    margin: -60px auto 0;

    @include tablet-screen{
      margin: 0 auto;
    }
  }
}

#content{
  padding: 80px 0 0;
}

.is-wrapper{
  background: $lightest-gray;
  padding: 35px 40px;

  .is-wrapper{
    background: $white;
    padding: 25px 30px;
  }
}

.is-transparent-wrapper{
  padding: 25px 30px;
}

#content > .container:last-child > .is-wrapper:last-child{
  margin-bottom: 90px;
}

.small-container{
  @include rem(font-size, 18px);
  margin-left: auto;
  margin-right: auto;
  max-width: 580px;
  width: 90%;
}