#home-quick-quote{
  font-family: $title-font;
  @include rem(font-size, 15px);

  &.slide{
    z-index: 5;
  }

  .decor-icon{
    background: rgba(12,17,37, 0.95);
    @include border-radius(100%);
    display: block;
    height: 56px;
    left: 0;
    margin: -28px 0 0 0;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    top: 50%;
    @include transition(0.2s all ease-in);
    width: 56px;
    z-index: 10;

    .material-icons{
      @include rem(font-size, 35px);
      left: 7px;
      position: absolute;
      top: 10px;
    }
  }

  &.is-active{
    .decor-icon{
      left: -26px;
      opacity: 1;
    }

    .background{
      background: rgba(0,0,0, 0.4 );
    }
  }

  .wrapper{
    color: $white;
    min-height: 100%;
    overflow-y: auto;
    padding: 0 0 0 40px;
    position: relative;
    width: 100%;
    z-index: 5;
  }
  .wrapper-inner{
    background: rgba(12,17,37, 0.95);
    height: 100%;
    padding-bottom: 145px;
    padding-top: 95px;
    position: relative;
  }
  .background{
    background: rgba(0,0,0, 0);
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    @include transition(0.4s all ease-in);
    width: 100%;
    z-index: 1;
  }

  .container{
    height: 100%;
  }

  .row{
    height: 100%;
  }

  .col-xs-12,
  .col-sm-12{
    .content{
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      
      height: 100%;
    }
  }
  ul{
    @extend %unstyledList;
    font-weight: 300;

    em{
      display: block;
      font-style: normal;
      font-weight: 400;
    }

    li{
      margin-bottom: 18px;
    }
  }
  .important-notice{
    font-weight: 300;
    margin-top: 25px;
    padding: 30px 0 0;
    position: relative;

    &:before{
      background: $c-primary;
      content: "";
      display: block;
      height: 10px;
      left: 0;
      max-width: 180px;
      position: absolute;
      top: 0;
      width: 90%;
    }

    strong{
      color: #f8f2c9;
      display: block;
      font-weight: 400;
    }
  }

  .total-table {
    background: $white;
    border-left: 20px #bdbdbd solid;
    color: $c-text;
    margin: 0 auto;
    min-width: 230px;
    padding: 35px 45px;

    @include min-screen(400px){
      min-width: 320px;
    }

    @include min-screen(460px){
      min-width: 390px;
    }

    ul{
      li{
        margin-bottom: 0;
      }
    }
    .button{
      margin: 20px 0 10px;
      text-transform: uppercase;
    }
  }
  .switch-slide{
    color: $c-secondary;
    font-family: $font-family;
    font-weight: normal;

    @include onHover{
      color: #1d3284;
    }
  }
}

#switch-btn{
  background: rgba(0,0,0,0.01);
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 40px;
  z-index: 10;

  &:hover{
    cursor: pointer;
  }
}