#review-accordion{
  margin-top: 30px;
  
  em{
    display: block;
    font-style: normal;
    font-weight: normal;
    margin-right: 15px;
    width: 100%;

    @include tablet-screen{
      display: inline-block;
      min-width: 170px;
      width: auto;
    }

    & + span{
      display: inline-block;
      font-weight: 300;
    }
  }

  .primary-outline-button{
    margin-top: 30px;
    width: 100%;

    @include tablet-screen{
      width: auto;
    }
  }

  ul{
    @extend %unstyledList;

    li{
      margin-bottom: 10px;
    }
  }
}