ul{
  padding-left: 15px;

  li{
    margin-bottom: 7px;
  }
}

ol.ordered-list{
  counter-reset: ordered-list-counter;
  list-style: none;
  padding-left: 30px;

  li{
    margin-bottom: 7px;
    counter-increment: ordered-list-counter;
    position: relative;

    &:before{
      color: $c-secondary;
      content: counter(ordered-list-counter)".";
      @include rem(font-size, 25px);
      left: -25px;
      position: relative;
      top: 0;
    }
  }
}

%unstyledList{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.unstyled-list{
  @extend %unstyledList;
}