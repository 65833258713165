footer {
  background: $gray;
  color: $white;
  text-align: center;

  & > div{
    @include align-items(center);
    @include flexbox();
    height: 100%;
    width: 100%;
  }

  p{
    margin: 0 auto;
    padding: 0;
  }
}