#order-window{
  @extend %window-style;

  @include tablet-screen{
    background: rgba(0,0,0,0.7);
    color: $white;
  }
  
  .wrapper{
    font-weight: 400;

    @include tablet-screen{
      font-size: 0;
      font-weight: 300;
    }
  }

  .wrapper-inner{
    @include tablet-screen{
      @include rem(font-size, 17px);
      margin-left: auto;
      margin-right: auto;
      max-width: 720px;
      padding: 10px 20px 5px;
      text-align: center;
      width: 95%;
    }

    @include min-screen(992px){
      max-width: 960px;
    }

    @include min-screen(1200px){
      max-width: 1140px;
    }
  }

  .header{
    @include tablet-screen{
      display: none;
    }
  }

  &.is-collapsed{
    .wrapper{
      @include tablet-screen{
        height: auto !important;
      }
    }
  }
}

#order-content{
  @include tablet-screen{
    color: $white;
    display: inline-block;
    @include rem(font-size, 16px);
    font-weight: 400;
    max-width: 70%;
    text-align: left;
    vertical-align: middle;
  }

  a{
    color: $c-secondary;
    text-transform: uppercase;

    @include onHover{
      color: $c-primary;
    }

    @include tablet-screen{
      color: $white;
      @include rem(font-size, 15px);
      text-transform: uppercase;
    }
  }

  em{
    font-style: normal;
    font-weight: 600;

    @include tablet-screen{
      font-weight: 400;
    }
  }
}

#order-total{
  font-size: 125%;

  @include tablet-screen{
    display: inline-block;
    font-size: 125%;
    max-width: 30%;
    padding-left: 20px;
    position: relative;
    text-align: left;
    vertical-align: middle;
  }

  &:after{
    @include tablet-screen{
      background: $white;
      content: "";
      display: block;
      height: 100%;
      min-height: 20px;
      position: absolute;
      left: 10px;
      top: 0;
      width: 3px;
    }
  }

  span{
    @include tablet-screen{
      display: none;
    }
  }
  em{
    color: $c-primary;
    display: inline-block;
    font-family: $title-font;
    font-style: normal;
    font-weight: 400;
    margin-left: 10px;

    @include tablet-screen{
      color: $yellow;
    }
  }
}