@import "alignment";
@import "titles";

.text-uppercase{
  text-transform: uppercase !important;
}

.smaller-text{
  font-size: 90%;
  line-height: 1.2em;
}

.larger-text{
  font-size: 110%;
}

.is-error,
.error-message{
  color: $red !important;
}

.has-indent{
  text-indent: 1em;

  p:first-child{
    text-indent: 0;
  }

  .no-indent{
    text-indent: 0;
  }
}