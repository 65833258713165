%btn{
  border: none;
  display: inline-block;
  font-weight: normal;
  @include rem(font-size, 22px);
  padding: 15px 10px;
  text-align: center;  
  text-decoration: none;
  @include transition(0.4s all ease-in);

  @include onHover{
    cursor: pointer;
    text-decoration: none;
  }

  &.expanded{
    width: 100%;
  }

  &.prev-btn{
    &:before{
      content: "< ";
    }
  }

  &.next-btn{
    &:after{
      content: " >";
    }
  }
}

.button{
  @extend %btn;
  background: $c-primary;
  border: 2px solid $c-primary;
  color: $white;

  @include onHover{
    background: $c-primary-highlight;
    border-color: $c-primary-highlight;
    color: $white;
  }
}

%outline-button-style{
  background: transparent;
  border-width: 2px;
  border-style: solid;
}

.outline-button{
  @extend %btn;
  @extend %outline-button-style;
  border-color: $dark-gray;
  color: $dark-gray;

  @include onHover{
    background: $dark-gray;
    color: $white;
  }
}

.primary-outline-button{
  @extend %btn;
  @extend %outline-button-style;
  border-color: $c-primary;
  color: $c-primary;
  max-width: 100%;
  min-width: 200px;

  @include onHover{
    background: $c-primary;
    color: $white;
  }
}

.outline-button + .button,
.button + .outline-button{
  margin-top: 15px;

  @include tablet-screen{
    margin-top: 0;
  }
}