.dual-block{
  position: relative;
  width: 100%;

  & > div{
    width: 100%;

    @include tablet-screen{
      @include align-items(center);
      @include flexbox();
    }

    & > div{
      margin: 0;
      padding: 20px 15px;

      @include tablet-screen{
        margin: auto;
        width: 50%;
      }

      &.is-empty{
        padding: 0;

        @include tablet-screen{
          padding: 20px 15px;
        }
      }
    }
  }
}