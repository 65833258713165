.total-table{
  ul{
    font-size: 0;
    list-style-type: none;
    margin: 0 auto;
    padding: 0;
    max-width: 220px;
    width: 100%;

    @include tablet-screen{
      margin: 0;
      max-width: 100%;
    }
  }

  li{
    span{
      display: inline-block;
      @include rem(font-size, $font-size);
      width: 50%;

      &:last-child{
        font-family: $title-font;
        font-weight: 300;
        text-align: right;
      }
    }
  }

  .total{
    margin: 0 auto;
    max-width: 220px;
    text-align: center;
    width: 85%;

    @include min-screen(420px){
      text-align: right;
    }

    @include tablet-screen{
      max-width: 100%;
      width: 100%;
    }

    & > div{
      border-top: 4px solid $orange;
      display: inline-block;
      margin: 15px 0 0;
      padding: 10px 8px;
    }

    h3, span{
      display: inline-block;
      font-weight: normal;
      vertical-align: middle;
    }

    h3{
      font-family: $font-family;
      margin: 0;
      position: relative;
      text-transform: none;
      top: 2px;
    }

    span{
      font-family: $title-font;
      @include rem(font-size, 21px);
    }
  }
}