.is-toggle{
  overflow: hidden;
  @include transition(0.4s height ease-in);
}

.triggers-toggle{
  display: inline-block;
  overflow: hidden;

  &.target-is-open{
    height: 0;
    opacity: 0;
    pointer-events: none;
  }
}