#home-banner, 
.home-banner{
  @extend %banner-style;
  background-image: url(../img/banner-home-xs.jpg);
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;


  @include tablet-screen{
    background-image: url(../img/banner-home.jpg);
  }

  .big-text{
    color: $white;
    text-shadow: 1px 1px 4px rgba(0,0,0,0.6);
    font-size: 38px;
    font-weight: 300;
    text-transform: none;
  }

  .slide{
    height: 100%;
    left: 100%;
    position: absolute;
    top: 0;
    min-height: 100vh;
    @include transition(0.4s left ease-in);
    width: 100%;
    z-index: 1;

    &:first-child{
      padding: 20px 0;
      z-index: 5;

      .container{
        overflow-y: auto;
        padding-bottom: 145px;
        padding-top: 95px;
      }
    }

    &.is-active{
      left: 0;
    }
  }
}

@import "homeform";
@import "home-quick-quote";