%window-style{
  background: #ededed;
  bottom: 0;
  color: $white;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 998;

  .header{
    color: $white;
    font-family: $title-font;
    @include rem(font-size, 17px);
    font-weight: 400;
    margin: 0;
    position: relative;
    text-transform: none;

    a{    
      background: #23386f;
      color: $white;
      display: block;
      padding: 20px 35px 20px 20px;
      position: relative;
      text-decoration: none;
      width: 100%;
      z-index: 1;

      @include onHover{
        background: #234396;
        text-decoration: none;
      }
    }
    .material-icons{
      color: $white;
      position: absolute;
      right: 15px;
      top: 18px;
      z-index: 3;
    }
  }

  .wrapper{
    color: $c-text;
    @include rem(font-size, 17px);
    overflow: hidden;
    @include transition(0.4s height ease-in );
  }
  
  .wrapper-inner{
    padding: 20px 20px 25px;
  }

  &.is-collapsed{
    .header{
      .material-icons{
        @include rotate(180deg);
      }
    }

    .wrapper{
      height: 0;
    }
  }
}

#cta-window{
  @extend %window-style;
  max-width: 400px;
  
  .primary-outline-button{
    @include rem(font-size, 18px);
    margin-top: 5px;
    padding-bottom: 9px;
    padding-top: 9px;
    width: 100%;
  }
}