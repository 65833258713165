@import "common/common";
@import "modules/modules";

html{
  font-size: 100%;
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

body{
  color: $c-text;
  @include rem(font-size, $font-size);
  font-family: $font-family;
  letter-spacing: 0.03em;
  line-height: 1.5em;
  scroll-behavior: smooth;
}