$font-size: 16px;
$font-family: 'Catamaran', sans-serif;
$title-font: 'Mitr', serif;

$screen-mobile:     420px;
$screen-tablet:     768px;
$screen-desktop:    992px;
$screen-large:      1200px;

// Colors
$black: #1f1f1f;
$blue: #1f2c60;
  $darker-blue: #0d1834;
  $light-blue: #2b43a5;
$gray: #747474;
  $dark-gray: #323232;
  $darker-gray: #373737;
  $lighter-gray: #cfcfcf;
  $lightest-gray: #f0f0f0;
$ivory: #f1f1f1;
$orange: #cd6825;
  $light-orange: #e16a1b;
$red: #9b0e0e;
$yellow: #fff69f;
$white: white;

$c-primary: $orange;
  $c-primary-highlight: $light-orange;
$c-secondary: $blue;

$c-border: $lighter-gray;
$c-text: $black;
$c-light-text: $gray;