.notification-list{
  @extend %unstyledList;
  font-size: 96%;

  li{
    min-width: 92px;
    padding: 0 0 0 30px;
    position: relative;   
    
    &.is-error{
      .material-icons{
        color: $red;
      }
    }
  }

  .material-icons{
    color: $c-primary;
    @include rem(font-size, 22px);
    left: 0;
    position: absolute;
    top: 0;
  }
}