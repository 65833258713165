.has-white-bck{
  background: $white;
}

.has-dark-background{
  background: $darker-gray;
  color: $white;
}

.has-yellow-bck{
  background: $yellow;
}

.has-gray-bck{
  background: $lighter-gray;
}
